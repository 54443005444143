import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import { Table, Input, Button, Select, Space, Modal } from 'antd';
import axios from 'axios';
import HeadersAdmin from '../../Components/HeadersAdmin';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ReactSummernote } from '../../Components/Summernote';

dayjs.locale('ko');

function FaqList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(10);
    const [ft_show, setFtshow] = useState('all');
    const [ftIdx, setFtIdx] = useState();
    const [no, setNumber] = useState(0);

    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
      {
        title: '질문',
        dataIndex: 'ft_title',
      },
      {
        title: '답변',
        dataIndex: 'ft_content',
        key: 'idx',
        render: (data) => <div>{data.replace(/(<([^>]+)>)/ig,"")}</div>
      },
      {
        title: '게시상태',
        dataIndex: 'ft_show',
         key: 'idx'
      },
      {
        title: '관리',
        dataIndex: 'idx',
        key: 'idx',
        render: (data, row) =>
        data === 'initial' 
          ? ( <span>-</span> ) 
          : (
              <div>
                <span style={{ marginRight: 10  }}>
                  <Button
                    onClick={() => {
                      navigate(`/faqlist-info/${data}`)
                    }}
                    // disabled={row.refundAmount}
                    type="primary"
                  >수정
                  </Button>
                </span>
                <span>
                  <Button
                  onClick={() => 
                    {
                      setFtIdx(data)
                      console.log(data)
                      Modal.confirm({ title: '자주하는 질문 삭제', content: '삭제 하시겠습니까?', 
                      onOk: () => deleteData(data), okText: 'Ok' });
                    }
                  }
                  // disabled={row.refundAmount}
                  type="default"
                  >삭제
                  </Button>
                </span>
              </div>
            ),
      },
    ];
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
          ft_show:ft_show
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/faq-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
    console.log(data)

    const reset = async () => {
      setSearch('');
      setSelect('all');
      setFtshow('all');
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
          ft_show:'all'

        };
        const token = sessionStorage.getItem('token');
        const res = await API.get('/admin/faq-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
          setData(res.data.list);
          setTotal(res.data.total);
          const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const deleteData = async(id) => {
      try {
        const res = await API.get(`admin/faq-delete?ft_idx=${id}`)
        console.log(res)
        Modal.success({ title: '자주하는 질문 삭제 완료', content: '질문이 삭제되었습니다.', 
        onOk: () => setFtIdx(), okText: 'Ok' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
    }

    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };
    
    const onSelect = (value) => {
      setSelect(value);
    };
    

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount, ftIdx, ft_show]);

    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
        setSelect(location.state.ft_show);
      }
    }, []);
    
    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>자주하는 질문</Title>
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }} onClick={() => navigate('/faqlist-addform')} >
                신규등록
                </Button>
              </Wrap>
              
             </Wrap>
             <Wrap style={{ marginBottom: 30, justifyContent: 'space-between'}}>
              <Wrap>
                <span style={{ marginRight: 10  }}>
                  <Button type={ft_show === 'all' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setFtshow('all')}>
                  전체
                  </Button>
                </span>
                <span style={{ marginRight: 10  }}>
                <Button type={ft_show === 'Y' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setFtshow('Y')}>
                  게시(Y)
                </Button>
                </span>
                <span style={{ marginRight: 10  }}>
                <Button type={ft_show === 'N' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setFtshow('N')}>
                  게시안됨(N)
                </Button>
                </span>
              </Wrap>
              <Wrap>
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 120,height:40 }}
                  options={[
                    { value: 'all', label: '통합검색' },
                    { value: 'ft_title', label: '질문' },
                    { value: 'ft_content', label: '답변' },
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
             </Wrap>
            <Table
              
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 15, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default FaqList;