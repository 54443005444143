import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../Utils/Color';
import img_logo from '../Assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { API } from '../Utils/API';
import { SettingOutlined, UserOutlined, HistoryOutlined, BarChartOutlined,MessageOutlined,EditOutlined,QuestionCircleOutlined,AlertOutlined,LogoutOutlined } from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import './headerAdmin.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenu } from '../store';

function HeadersAdmin() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState('');
  let state = useSelector((state) => state.openMenu)
  let dispatch = useDispatch()

  // console.log('state확인', state)
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const onLogout = () => {
    sessionStorage.removeItem('token');
    navigate('/login-admin');
  };

  const items = [
    getItem(<Link to={'/main'}><NavTitle>대시보드</NavTitle></Link>, 'main1', <BarChartOutlined />),
    getItem(<NavTitle>회원 관리</NavTitle>, 'main2', <UserOutlined />, [
      getItem(
        <Link to={'/members'}>
          <MenuText>일반회원 관리</MenuText>
          {/* <MenuText>가맹점 현황/</MenuText> */}
        </Link>,
        'sub1',
        null
      ),
      getItem(
        <Link to={'/memberout'}>
          <MenuText>탈퇴회원관리</MenuText>
        </Link>,
        'sub2',
        null
      ),
    ]),
    getItem( <Link to={'/pushlist'}><NavTitle>푸시알림관리</NavTitle></Link>, 'main3', <MessageOutlined />),
    getItem(<NavTitle>거래관리</NavTitle>, 'main4', <HistoryOutlined />, [
      getItem(
        <Link to={'/transactionlist'}>
          <MenuText>거래관리</MenuText>
        </Link>,
        'sub3'
      ),
      getItem(
        <Link to={'/transactioncompleted'}>
          <MenuText>거래완료/만족도순</MenuText>
        </Link>,
        'sub4'
      ),
      
    ]),
    getItem(<NavTitle>게시글관리</NavTitle>, 'main5', <EditOutlined />, [
      getItem(
        <Link to={'/eventlist'}>
          <MenuText>이벤트팝업관리</MenuText>
        </Link>,
        'sub5'
      ),
      getItem(
        <Link to={'/reviewlist'}>
          <MenuText>후기관리</MenuText>
        </Link>,
        'sub6'
      ),
      getItem(
        <Link to={'/noticelist'}>
          <MenuText>공지사항관리</MenuText>
        </Link>,
        'sub7'
      ),
      getItem(
        <Link to={'/categorylist'}>
          <MenuText>카테고리관리</MenuText>
        </Link>,
        'sub8'
      ),
      
    ]),
    getItem(<NavTitle>문의관리</NavTitle>, 'main6', <QuestionCircleOutlined />, [
      getItem(
        <Link to={'/faqlist'}>
          <MenuText>FAQ관리</MenuText>
        </Link>,
        'sub9'
      ),
      getItem(
        <Link to={'/qnalist'}>
          <MenuText>1:1문의관리</MenuText>
        </Link>,
        'sub10'
      ),
      
      
    ]),
    getItem(<NavTitle>설정관리</NavTitle>, 'main8', <SettingOutlined />, [
      getItem(
        <Link to={'/setting/ko'}>
          <MenuText>한국어</MenuText>
        </Link>,
        'sub11'
      ),
      getItem(
        <Link to={'/setting/eng'}>
          <MenuText>English</MenuText>
        </Link>,
        'sub12'
      ),
      getItem(
        <Link to={'/setting/in'}>
          <MenuText>Bahasa Indonesia</MenuText>
        </Link>,
        'sub13'
      ),
      
      
    ]),
    getItem( <Link to={'/declarationlist'}><NavTitle>신고관리</NavTitle></Link>, 'main7', <AlertOutlined />),
    // getItem( <Link to={'/setting'}><NavTitle>설정관리</NavTitle></Link>, 'main8', <SettingOutlined />),
    getItem( <MenuText
      onClick={() => {
        Modal.confirm({
          title: '알림',
          content: '로그아웃 하시겠습니까?',
          okText: 'Ok',
          onOk: () => {
            onLogout()
            navigate(`/`)
          } ,
          cancelText: '취소',
          onCancel: () => console.log('취소'),
        });
      }}
    >
      로그아웃
    </MenuText>, 'main9',<LogoutOutlined />),
  ];

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login-admin');
    }
    if (token) {
      const userIdx = jwt_decode(token).idx;
      if (userIdx === 49 || userIdx === 100055) {
      } else {
        navigate('/login-admin');
      }
    }
    if (sessionStorage.getItem('token')) {
      API.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
  }, []);

  const onClickMenu = (e) => {
    const keyPathLength = e.keyPath.length
    const subMenuPath = e.keyPath[1]
    const mainMenu = e.key

    if ( keyPathLength === 1) {
      dispatch(changeMenu(mainMenu))
    } else {
      dispatch(changeMenu(subMenuPath))
    }
  }

  useEffect(() => {
    if (openMenu != '') {
      console.log('useEffect', openMenu)
    }
  },[openMenu])
  
  return (
    <Container>
      <Section >
        <Link to={'/main'} style={{ borderbottom: '1px soild #fff'}}>
          <Logo src={img_logo} />
        </Link>
      </Section>
      <Menu
        onClick={(e) => onClickMenu(e)}
        style={{
          backgroundColor: ColorMainBlue,
          width: '100%',
        }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[state]} /* 'main1' */
        mode="inline"
        theme="dark"
        items={items}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 230px;
  min-height: 100vh;
  background-color: ${ColorMainBlue};
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 0px;
`;

const MenuText = styled.div`
  font-size: 15px;
  margin-left: 5px;
`;

const NavTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`;

const Logo = styled.img`
  padding: 30px;
`;

export default HeadersAdmin;
