import React, { useState, useEffect, createContext } from 'react';
import styled from 'styled-components';
import { API } from '../Utils/API';
import { ColorBlack, ColorMainBlue } from '../Utils/Color';
import sns_blog from '../Assets/images/sns_blog.png';
import sns_insta from '../Assets/images/sns_insta.png';
import sns_kakao from '../Assets/images/sns_kakao.png';
import sns_youtube from '../Assets/images/sns_youtube.png';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  const [use, setUse] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [faq, setFaq] = useState('');
  const [data, setData] = useState([]);
/*
  const insta = 'https://www.instagram.com/victok_v/';
  const kakao = 'http://pf.kakao.com/_Afxdxfb';
  const youtube = 'https://www.youtube.com/channel/UCU0DHWyc790ANpg9UbF83Ag';
  const blog = 'https://blog.naver.com/victok2022';
*/
  // const getData = async() => {
  //   const token = sessionStorage.getItem('token');
  //   // const tempToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZHgiOjQ5LCJpYXQiOjE2Nzg4NTcxNjB9.BDBbXFdm4EjBDOQcUuD6Mzx7vueT_Aog-GOb2dcdDto"
  //   try {
  //     const res = await API.get(`admin/setting`, {
  //       headers: { Authorization: `Bearer ${token ? token : null/* tempToken */ }` }
  //     })
  //     setData(res.data.data[0])
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log(error.response)
  //     }
  //   }
  // }

  const onInit = async () => {
    try {
      const res = await API.get('/user/terms');
      setUse(res.data[0].terms_of_use);
      setPrivacy(res.data[0].privacy_policy);
      setFaq(res.data[0].faq);
    } catch {}
  };

  useEffect(() => {
    // getData();
    onInit();
  }, []);

  return (
    <WhiteBg>
      <Container>
        <Wrap>
          <Wrap>
            <Terms href={use} target="_blank">
              GETGO
            </Terms>
          </Wrap>
          {/* <Text style={{ marginTop: 10 }}>
            대표이사 : {data.st_company_boss} <Br/>|<Br/>
            사업자등록번호 : {data.st_company_num1} <Br/>|<Br/>
            통신판매신고번호 : {data.st_company_num2}
          </Text>
          <Text>
            {data.st_company_add} <Br/>|<Br/>
            대표전화 : {data.st_customer_tel}
          </Text> */}
          <Text style={{ marginTop: 10 }}>
            대표이사 : 홍길동 <Br/>|<Br/>
            사업자등록번호 : 209-81-57303 <Br/>|<Br/>
            통신판매신고번호 : 제2018-서울강남-02337 호
          </Text>
          <Text>
            서울특별시 강남구 테헤란로 211 한국고등교육재단빌딩 3층 <Br/>|<Br/>
            대표전화 : 02-1234-5678
          </Text>
          <Label style={{ marginTop: 10 }}>copyright 2023 GETGO All rights reserved.</Label>
        </Wrap>
        
        
      </Container>
    </WhiteBg>
  );
}
const WhiteBg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  height: 180px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  padding: 30px 0;
  @media screen and (max-width: 1450px) {
    width: 100%;
    padding: 30px 20px;
  }
`;

const Wrap = styled.div``;

const Label = styled.p`
  display: flex;
  font-size: 14px;
  color: ${ColorBlack};
  font-weight: 200;
  margin: 0;
`;

const Terms = styled.a`
  font-weight: bold;
  font-size: 15px;
  color: ${ColorMainBlue};
  margin-right: 30px;
`;

const SnsLink = styled.a`
  margin: 0 9px;
  &:last-child {
    margin-right: 0;
  }
`;

const SnsImage = styled.img`
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  transition: 0.3s ease;
`;

const Text = styled.p`
  color: ${ColorBlack};
  font-size: 13px;
  margin: 0;
`;

const Br = styled.span`
  margin: 0 6px;
`

export default Footer;
