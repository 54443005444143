import { Table, Input, Button, Select, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function DeclarationList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState('10');
    const [dct_type, setDcttype] = useState('');
    const [dct_status, setDctstatus] = useState('');
    const [idx, setIdx] = useState();
    const [no, setNumber] = useState(0);
   
    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
      {
       
        title: '처리상태',
        dataIndex: 'dct_status',
        render: (data) => <span>{data === 1 ? '처리전' : (data === 2) ? '승인' : (data === 3) ? '반려' : '처리전'}</span>,
      },
      {
        
        title: '신고구분',
        dataIndex: 'dct_type',
        key: 'idx',
        render: (data) => <span>{data === 1 ? '사용자신고' : (data === 2) ? '사용자신고' : (data === 3) ? '게시글신고' : '사용자신고'}</span>,
       
 
      },
      {
        
        title: '게시글',
        dataIndex: 'mt_id',
        key: 'idx',
        render: (data,row) => <span>{row.dct_type === 1 ? '채팅' : (row.dct_type === 2) ? '상품상세' : (row.dct_type === 3) ? '후기' : '채팅'}</span>,
        
      },
      {
        
        title: '신고자',
        dataIndex: 'mt_nickname',
        key: 'idx',
        render:  (data,row) => <div style={{ display: 'flex', flexDirection: 'row' }}><div style={{  flexDirection: 'column',marginLeft: "10px" }}> <div style={{paddingleft:"10px",flexDirection: 'row'}}>{row.mt_hp}</div><div> {data} </div></div></div>
        
      },
      {
        
        title: '신고사유',
        dataIndex: 'dct_reason',
        key: 'idx',
        render: (data,row) => <span>{data === "기타" ? (row.dct_reason_etc) : (data)}</span>,

     },
     {
        
      title: '신고일',
      dataIndex: 'dct_wdate',
      key: 'idx',
     

      },
      {
        
        title: '작성자',
        dataIndex: 'wmt_nickname',
        key: 'idx',
        render:  (data,row) => <div style={{ display: 'flex', flexDirection: 'row' }}><div style={{  flexDirection: 'column',marginLeft: "10px" }}> <div style={{paddingleft:"10px",flexDirection: 'row'}}>{row.wmt_hp}</div><div> {data} </div></div></div>
        
      },
      {
        
        title: '게시글내용',
        dataIndex: 'dt_content',
        key: 'idx',
        render: (data,row) => <span>{row.dct_type === 1 ? (row.pt_title) : (row.dct_type === 2) ? (row.pt_title) : (row.dct_type === 3) ? (row.rt_title) : (row.pt_title)}</span>,
        
      },
      {
      
        title: '관리',
        dataIndex: 'idx',
        key: 'idx',
        render: (data, row) =>
        data === 'initial' 
          ? (<span>-</span>) 
          : (<div>
              <span style={{ marginRight: 10  }}>
                <Button
                  onClick={() => {
                    navigate(`/declarationlist-info/${data}`)
                  }}
                  // disabled={row.refundAmount}
                  type="primary"
                > 상세
                </Button>
              </span>
              <span>
                <Button
                  onClick={() => {
                    console.log(data)
                    setIdx(data)
                    onDeleteBtn(data)
                  }}
                  // disabled={row.refundAmount}
                  type="default"
                > 삭제
                </Button>
              </span>
            </div>),
      },
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
          dct_status:dct_status,
          dct_type:dct_type
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/declaration-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        console.log(res.data.list);
        console.log(res.data.total);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };

    const reset = async () => {
      setSearch('');
      setSelect('all');
      setDcttype('');
      setDctstatus('')
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
          dct_status:'',
          dct_type:''
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/declaration-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const deleteData = async(id) => {
      try {
        const res = await API.get(`admin/declaration-delete?dt_idx=${id}`)
        console.log('삭제된 결과: ',res)
        Modal.success({ title: '신고내역 삭제 완료', content: '신고내역이 삭제되었습니다.', 
        onOk: () => setIdx(), okText: 'Ok' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
    }

    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    const onSelect = (value) => {
      setSelect(value);
    };
    console.log(filter)

    const onDeleteBtn = (id) => {
      Modal.confirm({ title: '신고하기 삭제', content: '삭제 하시겠습니까?', 
      onOk: () => deleteData(id), okText: 'Ok' });
    }
      
    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount, idx, dct_type, dct_status]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };


  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>신고관리</Title>
              </Wrap>
            </Wrap>
            <Wrap style={{ marginBottom: 10, justifyContent: 'space-between'}}>
              <Wrap>
                <span style={{ marginRight: 10  }}>
                <Button type={dct_type === '2' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setDcttype('2')}>
                  사용자신고
                </Button>
                </span>
                <span style={{ marginRight: 10  }}>
                <Button type={dct_type === '3' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setDcttype('3')}>
                 게시글신고
                </Button>
                </span>
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 95, height:40, textAlign: 'center' }}
                  options={[
                    { value: 'all', label: '선택' },
                    { value: '1', label: '채팅' },
                    { value: '2', label: '상품상세' },
                    { value: '3', label: '후기' },
                  ]}
                />
              </Wrap>
              <Wrap>
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
              
            </Wrap>
            <Wrap style={{ marginBottom: 30  }}>
                <span style={{ marginRight: 10  }}>
                  <Button type={dct_status === '1' ? 'primary' : 'Default'}  style={{ width: 98, height: 40 }} onClick={() => setDctstatus('1')}>
                  처리전
                  </Button>
                </span>
                <span style={{ marginRight: 10  }}>
                <Button type={dct_status === "2" ? 'primary' : 'Default'}  style={{ width: 98, height: 40 }} onClick={() => setDctstatus('2')}>
                  숭인
                </Button>
                </span>
                <span style={{ marginRight: 10  }}>
                <Button type={dct_status === "3" ? 'primary' : 'Default'}  style={{ width: 98, height: 40 }} onClick={() => setDctstatus('3')}>
                반려
                </Button>
                </span>
                
                         
              
             </Wrap>
            <Table
              
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 10, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              scroll={{ x: 1800 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default DeclarationList;