import { Table, Input,Button,Select,Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function EventList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(10);
    const [et_show, setEtshow] = useState('all');
    const [no, setNumber] = useState(0);
    const [etIdx, setEtIdx] = useState();
    

    

    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
        {
       
        title: '게시 구분',
        dataIndex: 'et_show',
        
      },
      {
        
        title: '팝업창이미지',
        dataIndex: 'et_file1',
        key: 'idx',
        render:  (data) => <img src={`https://getgoapi.dmonster.kr/uploads/${data}`}  className='custom-banner'/>
 
      },
      {
        
        title: '등록일',
        dataIndex: 'et_wdate',
        key: 'idx',
        render: (data) => (<span>{dayjs(data).format('YY.MM.DD')}</span>),

      },

      {
      
        title: '관리',
        dataIndex: 'idx',
        key: 'idx',
        render: (data, row) =>
        data === 'initial' ? (
          <span>-</span>
        ) : (<div>
          <span style={{ marginRight: 10  }}>
          <Button
            onClick={() => {
              navigate(`/event-info/${data}`);
             }}
            // disabled={row.refundAmount}
            type="primary"
          >
            관리
          </Button>
          </span>
          <span>
          <Button
                  onClick={() => {
                    setEtIdx(row.idx)
                    onDeleteBtn(row.idx)
                  }}
                  type="default"
                  > 삭제
         </Button>
        </span>
          </div>
         
          
        ),
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
    
      
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
          et_show:et_show
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/event-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        console.log(res.data.total)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };


    const reset = async () => {
      setSearch('');
      setSelect('all');
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
          et_show:"all"
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/event-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    
    
    const onSelect = (value) => {
      setSelect(value);
      
    };
    
  
    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount, et_show]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const onDelete = async (idx) => {
      
      try {
        console.log(idx)
        const res = await API.put(`admin/event-delete`,{idx:idx})
        console.log(res.data.message)
        Modal.success({ title: '이벤트팝업 삭제완료', content: '이벤트팝업이 삭제되었습니다.', 
        onOk: () =>  getData(), okText: 'Ok' });
      
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
     
     
     
    };

    const onDeleteBtn = async (idx) => {
      Modal.confirm({ title: '이벤트 삭제', content: '정말로 삭제하시겠습니까?', 
      onOk: () => onDelete(idx), okText: 'Ok' });
    }
  


  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
          <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>이벤트팝업창 관리</Title>
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={() => {
                  navigate(`/event-addform`);
                 }}>
                신규등록
                </Button>
              </Wrap>
              
             </Wrap>
             <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
                <Wrap>
                  <span style={{ marginRight: 10  }}>
                    <Button type={et_show === 'all' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setEtshow('all')}>
                    전체
                    </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={et_show === 'Y' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setEtshow('Y')}>
                    게시(Y)
                  </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={et_show === 'N' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setEtshow('N')}>
                  게시안함(N)
                  </Button>
                  </span>
                </Wrap>
                <Wrap>
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 120,height:40 }}
                  options={[
                    { value: 'all', label: '통합검색' },
                    { value: 'et_content', label: '이벤트내용' },
                    
                   
                  
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="이벤트내용을 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
             </Wrap>
           
            <Table
              
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 10, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default EventList;