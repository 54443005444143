import { Table, Button,Switch, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function CategoryList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(30);
    const [no, setNumber] = useState(0);
    const [ct_idx, setCtIdx] = useState();
    



    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
        {
       
        title: '게시여부',
        dataIndex: 'ct_show',
        sorter: true,
        key: 'ct_id',
        render: (text, row) => (
          <Switch checkedChildren="ON" onClick={() => onStatusChange(row.ct_id, text)} unCheckedChildren="OFF" checked={text === 'Y'} />
        ),
      },
      {
        
        title: '이미지',
        dataIndex: 'ct_file1',
        key: 'ct_id',
        
        render:  (data) => <img src={`https://getgoapi.dmonster.kr/uploads/${data}`}  className='custom-avt'/>
       
 
      },
      {
        
        title: 'English',
        dataIndex: 'ct_en_name',
         key: 'ct_id'

        
      },
      {
        
        title: 'Bahasa Indonesia',
        dataIndex: 'ct_in_name',
         key: 'ct_id'

        
      },
      {
        
        title: '한국어',
        dataIndex: 'ct_name',
         key: 'ct_id'

        
      },
      {
        
        title: '출력순위',
        dataIndex: 'ct_rank',
         key: 'ct_id'

        
      },
     
      

      {
      
        title: '관리',
        dataIndex: 'ct_id',
        key: 'ct_id',
        render: (data, row) =>
        data === 'initial' ? (
          <span>-</span>
        ) : (<div>
          <span style={{ marginRight: 10  }}>
          <Button
            onClick={() => {
              navigate(`/category-info/${data}`);
            }}
            // disabled={row.refundAmount}
            type="primary"
          >
            수정
          </Button>
          </span>
          <span>
          <Button
                  onClick={() => {
                    setCtIdx(row.ct_id)
                    onDeleteBtn(row.ct_id)
                  }}
                  type="default"
                  > 삭제
         </Button>
        </span>
          </div>
         
          
        ),
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      console.log(pagination.current)
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
    
      
      try {
        const formdata = {
          page: page,
          amount: amount,
        
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/category-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };


    const onStatusChange = async (idx, status) => {
      try {
        const res = await API.put('/admin/category-status', { ct_id: idx, status });
        getData();
      } catch {}
    };

   



    const onDelete = async (idx) => {
      
      try {
        console.log(ct_idx)
        
        const res = await API.put('/admin/category-delete',{ct_id:idx})
        console.log(res.data.message)
        Modal.success({ title: '카테고리 삭제완료', content: '카테고리가 삭제되었습니다.', 
        onOk: () =>  getData(), okText: 'Ok' });
      
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
     
     
     
    };

    const onDeleteBtn =  async (idx) => {
     
      Modal.confirm({ title: '카테고리 삭제', content: '정말로 삭제하시겠습니까?', 
      onOk: () => onDelete(idx), okText: 'Ok' });
    }
  
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    
    
    const onSelect = (value) => {
      setSelect(value);
      
    };
    
  // getData();
    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [/* page, */ amount]);

    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };


  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
          <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>카테고리관리</Title>
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }}   onClick={() => {
                  navigate(`/category-addform`);
                 }}>
                신규등록
                </Button>
              </Wrap>
              
             </Wrap>
           
            <Table
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 30, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='ct_id'
              
              scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default CategoryList;