import { Table, Input,Button,Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function TransactionCompleted() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(10);
    const [orderby, setOrderby] = useState("1");
    

    

    const columns = [
        {
        
            title: '순위',
            dataIndex: 'idx',
            key: 'idx',
            render:(value, item, index) => (page - 1) * 10 + index+1
         },
        {
       
        title: '판매자',
        dataIndex: 'mt_nickname',
        render:  (data,row) => <div style={{ display: 'flex', flexDirection: 'row' }}><div style={{  flexDirection: 'column',marginLeft: "10px" }}> <div style={{paddingleft:"10px",flexDirection: 'row'}}>{row.mt_hp}</div><div> {data} </div></div></div>
      },
      {
        
        title: '이달 거래완료건',
        dataIndex: 'month_count',
        key: 'idx'
       
 
      },
      {
        
        title: '누적 거래완료건',
        dataIndex: 'accumulate_count',
        key: 'idx'

        
      },

      {
        
        title: '이달 만족도건',
        dataIndex: 'month_rate',
        key: 'idx',
        

      },
      {
        
        title: '이달 만족도건',
        dataIndex: 'accumulate_rate',
        key: 'idx',
       
      },

      
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
          orderby: orderby,
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/complete-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
          setData(res.data.list);
          setTotal(res.data.total);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };

    const reset = async () => {
      setSearch('');
      setSelect('all');
      setOrderby('all');
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
          orderby: orderby,
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/complete-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    
    
    const onSelect = (value) => {
      setSelect(value);
      
    };
    
  
    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount, orderby]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };


  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>거래완료/만족도순</Title>
              </Wrap>
              
              </Wrap>
              <Wrap style={{ marginBottom: 10, justifyContent: 'space-between' }}>
                <Wrap>
                  <span style={{ marginRight: 10  }}>
                    <Button type={orderby === '1' ? 'primary' : 'Default'}  style={{ width: 140, height: 40 }} onClick={() => setOrderby('1')}>
                    이달의 거래완료건
                    </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={orderby === "2" ? 'primary' : 'Default'}  style={{ width: 140, height: 40 }} onClick={() => setOrderby('2')}>
                    누적 거래완료건
                  </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={orderby === "3" ? 'primary' : 'Default'}  style={{ width: 140, height: 40 }} onClick={() => setOrderby('3')}>
                  이달의 만족도건
                  </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={orderby === "4" ? 'primary' : 'Default'}  style={{ width: 140, height: 40 }} onClick={() => setOrderby('4')}>
                  누적 만족도건
                  </Button>
                  </span>
                </Wrap>
                <Wrap>
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 150,height:40 }}
                  options={[
                    { value: 'all', label: '통합검색' },
                    { value: 'mt_nickname', label: '판매자 닉네임' },
                    { value: 'mt_hp', label: '판매자 휴대폰번호' },
           
                                     
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
              
             </Wrap>
           
            <Table
              
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 10, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default TransactionCompleted;