import React, { useEffect, useState,useCallback } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Button, Divider, Modal } from 'antd';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import Eventform from '../../Components/EventForm';


// 관리자 - 이벤트관리

function EventAddForm() {
 const navigate = useNavigate();
 const { idx } = useParams();



 const [infoData, setInfoData] = useState({
    category: { 1: {idx:idx , et_content: '',image: null, et_show: "Y" } },
   
  });

  const fetchData = async () => {
    const res = await API.get('/admin/event-info?idx='+idx);
    const resData = res.data;

    let data = {};
    const types = ['category'];
    for (const type of types) {
      data = { ...data, [type]: { ...infoData[type], ...resData[type] } };
    }
    setInfoData(resData);
    navigate(`/eventlist`)
    console.log('데이텉너터터텉', data);
  };


  const onSave = async (type) => {
    try {
      const form = new FormData();
      const category = infoData[type];
      form.append('type', type);
      const idxs = Object.keys(category);
      console.log('idxs',idxs)
      for (const idx of idxs) {
        const item = category[idx];

        if (item.image) {
          // form.append('idx', idx);
          form.append(`et_content`, item.et_content);
          form.append(`et_show`, item.et_show);
          form.append(`image`, item.image);

        }
      }
      const res = await API.post('/admin/event_add', form, { headers: { 'Content-Type': 'multipart/form-data' } });
      Modal.success({ title: '이벤트  등록완료', content: '이벤트가 저장되었습니다.', onOk: () => fetchData(), okText: 'Ok' });
    } catch (error) {
      console.error('이벤트등록 에러', error);
      if (axios.isAxiosError(error)) {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    //fetchData();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ width: '600px' }}>
            <div style={{ width: '100%' }}>
              <Title id="title">이벤트 등록</Title>
              
            </div>
           
            <Divider />
            
              <ContentBox>
                <Eventform
                  title={'이벤트명'}
                  size={'1390x270'}
                  infoData={infoData.category[1]}
                  setInfoData={setInfoData}
                  type="category"
                  index={1}
                  width={'417px'}
                  height={'81px'}
                />
                <Divider />
                <Wrap style={{ marginBottom: 10  }}>
                <div>
                    <span style={{ marginRight: 10  }}> 
                <Button style={{ height: 50, fontSize: 16, borderRadius: 4, marginTop: 20,width:"45%" }} type="default" onClick={() => {
                  navigate(`/eventlist/`);
                 }}>
                  목록
                </Button>
                </span>
                <span> 
                <Button style={{ height: 50, fontSize: 16, borderRadius: 4, marginTop: 20,width:"45%" }} type="primary" onClick={() => onSave('category')}>
                  저장
                </Button>
                </span>
                </div>
                </Wrap>
              </ContentBox>
            
              
          </Wrap>
        </Box>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;





const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export default EventAddForm;